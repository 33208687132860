export default class Notification {
  constructor() {
    if (!$?.hasOwnProperty('jscroll')) return
    this.initDom()
    this.setEvents()
  }

  initDom() {
    this.$html = $('html')
    this.$initiator = $('.js-Notification')
    this.$list = this.$initiator.next()
    this.$readAll = this.$list.find('[data-notification-read-all]')
    this.$container = this.$list.find('[data-notification-loading-image]')
  }

  setEvents() {
    if (this.$initiator.length < 1) return
    this.setScrollEvent()

    $(document).on('click', (e) => {
      this.changeDisplay(e)
    })

    this.$readAll.one('click', () => {
      this.bulkReadRequest()
    })
  }

  setScrollEvent() {
    this.$container.jscroll({
      // debug: true,
      loadingHtml: this.createLoading().get(0).outerHTML,
      nextSelector: 'a[data-notification-paginate]',
      contentSelector: '.st-NotificationList_Item',
      padding: 100,
    })
  }

  createLoading() {
    const $img = $('<img />', {
      src: this.$container.get(0).dataset.notificationLoadingImage,
    })
    return $('<div>', {
      class: 'st-NotificationList_Loading',
    }).append($img)
  }

  changeDisplay({ target }) {
    const hideAction = () => {
      this.$list.hide()
      this.$html.removeClass('-notification-locked')
    }

    if (
      !$(target).closest('.st-Notification').length &&
      !$(target).closest('.st-NotificationList').length
    ) {
      hideAction()
    } else if ($(target).closest('.st-Notification').length) {
      if (this.$list.is(':hidden')) {
        this.$list.show()
        this.$html.addClass('-notification-locked')
        // jscrollのイベントを発火させる
        // this.$containerがvisibleの時にしかjScrollは動かないので注意
        // このイベントは通知欄を開くたびに発火するが、通知欄のscroll位置がロードするべきポジションでは無い限り2回目以降は読み込みは発生しない
        this.$container.trigger('scroll.jscroll')
      } else {
        hideAction()
      }
    } else if ($(target).closest('.st-NotificationList_Close').length) {
      if (!this.$list.is(':hidden')) {
        hideAction()
      }
    }
  }

  bulkReadRequest() {
    return $.ajax({
      type: 'PATCH',
      url: '/notification/bulk_update',
      dataType: 'json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader(
          'X-CSRF-Token',
          $('meta[name=csrf-token]').attr('content')
        )
      },
    }).done((data, textStatus, { status }) => {
      if (status === 204) {
        this.$initiator.get(0).dataset.notificationUnread = false
        this.$list.find('[data-read-status="false"]').each((_, elem) => {
          elem.dataset.readStatus = true
        })
      }
    })
  }
}
